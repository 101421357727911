import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

import SEO from '../components/seo';
import Subheader from '../components/subheader';
import { GraphQLData } from '../types';
import { getLocalizationObj } from '../utilities/getLocalizationObj';
import { IndexPageTemplateMain } from './indexMain';

interface IndexProps {
  data: GraphQLData;
}

export const IndexPageTemplate = ({ data }: IndexProps) => {
  const { language } = useI18next();
  const { subheader, meta } = getLocalizationObj(
    data.strapi.landingPage,
    language
  );
  const { title = 'Home', description } = meta || {};

  return (
    <>
      <SEO description={description} title={title} />
      <Subheader address intro={subheader} />
      <IndexPageTemplateMain data={data} />
    </>
  );
};
