import { graphql, useStaticQuery } from 'gatsby';
import { Link as GLink, Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { Box, Flex, Link } from 'juhuui';
import React from 'react';

import LinkWithIcon from '../components/linkWithIcon';
import {
    ContainerOuter, grid12, grid6, GridHalfFull, HeadingLg, PADDING_RIGHT_OVERLAY_CONTAINER, TextMd
} from '../styles';
import { getLocalizationObj } from '../utilities/getLocalizationObj';
import { Carousel } from './carousel';

// import MovieSlider from './movieSlider';

function Praxis({ carousel, description }) {
  const { t } = useTranslation();
  const { language } = useI18next();

  const data = useStaticQuery(graphql`
    query PraxisQuery {
      strapi {
        landingPage {
          localizations {
            praxisTitle
            praxisSubtitle
            praxisDescription
            praxisCarousel {
              id
              description
              title
              image {
                alternativeText
                caption
                url
                file {
                  ...Image
                }
              }
            }
          }
          praxisTitle
          praxisSubtitle
          praxisDescription
          praxisCarousel {
            id
            description
            title
            image {
              alternativeText
              caption
              url
              file {
                ...Image
              }
            }
          }
        }
      }
    }
  `);

  const { praxisTitle, praxisSubtitle, praxisDescription, praxisCarousel } =
    getLocalizationObj(data.strapi.landingPage, language) || {};

  return (
    <ContainerOuter
      className={`praxis ${grid12}`}
      css={{
        pb: [16, undefined, 0],
        bg: 'tom.bg',
        margin: 'auto',
        maxW: 'var(--max-width)',
        px: 'var(--px)',
        whiteSpace: 'pre-wrap',
      }}
    >
      <GridHalfFull
        css={{
          d: 'flex',
          direction: 'column',
          justify: 'space-between',
          pr: PADDING_RIGHT_OVERLAY_CONTAINER,
        }}
        fw={{ first: true }}
      >
        <HeadingLg
          as="h2"
          classNames="col-span-12 pointer-events-none z-1"
          // css={{ column: '1/span 12', pointerEvents: 'none', zIndex: '1' }}
        >
          {praxisTitle}
        </HeadingLg>
      </GridHalfFull>
      <GridHalfFull
        css={{
          d: 'flex',
          direction: 'column',
          justify: 'space-between',
          pr: PADDING_RIGHT_OVERLAY_CONTAINER,
        }}
      >
        <TextMd css={{ hyphens: 'auto' }}>{praxisDescription}</TextMd>
        <LinkWithIcon text={t('our practice')} to={`/${t('practice link')}`} />
      </GridHalfFull>
      <Carousel description={praxisSubtitle} items={praxisCarousel} />
    </ContainerOuter>
  );
}

export default Praxis;
