import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { Box } from 'juhuui';
import React from 'react';
import shallow from 'zustand/shallow';

import Hero from '../components/heroAnimated';
import LinkWithIcon from '../components/linkWithIcon';
// import Layout from '../containers/layout';
import ListBoxBorder from '../containers/listBoxBorder';
import ListYBorder, { Item } from '../containers/listYBorder';
import Praxis from '../containers/praxis';
import {
    Bright, ContainerOuter, grid12, grid6NoGap, GridHalfFull, HeadingLg, HeadingSm, LinkFrame,
    MainContent, MediumBright, TextMd
} from '../styles';
import { GraphQLData } from '../types';
import { getLocalizationObj } from '../utilities/getLocalizationObj';
import { useStore } from '../zustand';

interface IndexProps {
  data: GraphQLData;
}

export const IndexPageTemplateMain = ({ data }: IndexProps) => {
  const { language } = useI18next();
  const { t } = useTranslation();

  const {
    cover,
    methodsTitle,
    methodsSubtitle,
    disciplineTitle,
    disciplineSubtitle,
    disciplines,
    methods,
  } = getLocalizationObj(data.strapi.landingPage, language) || {};

  // set overlay content
  // const [, setModalLevel1] = useAtom(modalLevel1State);
  const [setModalLevel1Visible, setModalLevel1Data] = useStore(
    (state) => [state.setModalLevel1Visible, state.setModalLevel1Data],
    shallow
  );

  const changeModal = ({ id, ...rest }, type: string) => {
    setModalLevel1Visible(true);
    setModalLevel1Data({ name: type, id, ...rest });
  };

  return (
    <>
      <MainContent css={{ borderTop: 'none' }}>
        <Hero alt={cover?.alternativeText} src={cover?.file} />
        <Bright>
          <ContainerOuter
            className={grid12}
            css={{
              bg: 'tom.bgBright',
              margin: 'auto',
              maxW: 'var(--max-width)',
              position: 'relative',
              px: 'var(--px)',
            }}
          >
            <GridHalfFull
              css={{
                column: ['1/span 12', undefined, '1/span 5'],
                py: [6, '0'],
              }}
              fw={{ first: true }}
            >
              <Box
                css={{
                  position: ['relative', undefined, 'sticky'],
                  top: ['unset', undefined, '10'],
                }}
              >
                <HeadingLg
                  as="h2"
                  classNames="mb-6 lg:mb-20 whitespace-pre-wrap"
                  // css={{
                  //   mb: ['6', undefined, '20'],
                  //   whiteSpace: 'pre-wrap',
                  // }}
                >
                  {disciplineTitle}
                </HeadingLg>
                <TextMd css={{ hyphens: 'auto' }}>{methodsTitle}</TextMd>
                <LinkWithIcon
                  css={{
                    pb: ['initial', undefined, '0'],
                  }}
                  text={t('all services')}
                  to={`/${t('services link')}`}
                />
              </Box>
            </GridHalfFull>
            <GridHalfFull css={{ pt: ['8', undefined, '0'] }}>
              <HeadingSm
                as="h3"
                classNames="pb-3 pr-[20%]"
                // css={{
                //   pb: [3],
                //   pr: '20%',
                // }}
              >
                {disciplineSubtitle}
              </HeadingSm>
              {disciplines && (
                <ListYBorder>
                  {disciplines
                    .sort(
                      (first: any, second: any) => first.order - second.order
                    )
                    .map((item: Item, i: number) => (
                      <LinkFrame
                        key={item.id}
                        animate="true"
                        as="li"
                        css={{
                          '&::before': {
                            verticalAlign: ['middle'],
                            content: ['counter(counter)'],
                            position: ['relative', undefined, 'absolute'],
                            display: ['block'],
                            w: ['5', undefined, '3'],
                            left: ['', undefined, '-1.5rem'],
                            textAlign: ['left', undefined, 'center'],
                            fontSize: ['0.8em'],
                            top: '25%',
                            flexShrink: '0',
                          },
                          alignItems: 'baseline',
                          counterIncrement: 'counter',
                          as: 'li',
                          cursor: 'pointer',
                          d: ['flex', undefined, 'block'],
                          m: '0',
                          pos: 'relative',
                          // _hover={{ color: 'tom.textHighlight' }}
                          py: ['2', '1'],
                        }}
                        text="hover"
                        onClick={() => changeModal(item, 'competency')}
                      >
                        <Box
                          as="p"
                          className="link-text"
                          css={{
                            d: ['inline-block', undefined, 'block'],
                          }}
                        >
                          {item.title}
                        </Box>
                      </LinkFrame>
                    ))}
                </ListYBorder>
              )}
              <Box className={grid6NoGap} css={{ mt: ['16', undefined, '32'] }}>
                <HeadingSm
                  as="h3"
                  classNames="col-span-6 pb-3 pr-[33%]"
                  // css={{
                  //   column: ['1/span 6', undefined, '1/span 6'],
                  //   pb: [3],
                  //   pr: '33%',
                  // }}
                >
                  {methodsSubtitle}
                </HeadingSm>
                {methods && (
                  <ListBoxBorder
                    items={methods.sort(
                      (first: any, second: any) => first.order - second.order
                    )}
                  />
                )}
              </Box>
            </GridHalfFull>
          </ContainerOuter>
        </Bright>
        <MediumBright>
          <Praxis />
        </MediumBright>
      </MainContent>
    </>
  );
};
