import { Link as GLink } from 'gatsby-plugin-react-i18next';
import { Flex, Icon } from 'juhuui';
import React from 'react';

import { LinkFrame, TextSm } from '../styles';

interface LinkWithIconProps {
  text: string;
  to?: string;
  [x: string]: any;
}

function LinkWithIcon({ to, text, css, ...props }: LinkWithIconProps) {
  return (
    <LinkFrame
      animate="true"
      as={to ? GLink : 'div'}
      className="link-with-icon"
      css={{
        align: 'center',
        cursor: 'pointer',
        d: 'flex',
        py: ['4'],
        ...css,
      }}
      icon="hover"
      to={to || undefined}
      {...props}
    >
      <TextSm className="link-text" css={{ c: 'tom.textHighlight' }}>
        {text}
      </TextSm>
      <Icon
        className="link-icon"
        css={{
          ml: ['4'],
          size: ['6'],
          flexShrink: '0'
        }}
        name="more"
      />
    </LinkFrame>
  );
}

export default LinkWithIcon;
