import { graphql, useStaticQuery } from 'gatsby';
import { AspectRatioBox, Box, Flex, Heading, Icon } from 'juhuui';
import React, { useEffect, useRef, useState } from 'react';
import { a, useChain, useSpring, useTransition } from 'react-spring';

import Image, { ImageType } from '../components/image';
import {
    Animated, grid12, grid6, GridHalfFull, HeadingMd, HeadingSm, LinkFrame, TextMd
} from '../styles';
import Accordion from './accordion';
import { Markdown } from './markdown';

const AnimatedAspectRatioBox = AspectRatioBox.as(a.div).with();
const Flexy = Flex.with();

const CarouselItem = ({
  index,
  active,
  setActive,
  item,
  length,
  animationStyle,
}) => {
  const isActive = index === active;
  // useEffect(() => {
  //   setBoxWidth(boxRef.current?.clientWidth);
  // }, [windowWidth, isActive]);
  const { description, title, image } = item;

  const moveRef = useRef();
  const aMoveStyle = useSpring({
    flexGrow: isActive ? '1' : '0',
    config: {
      mass: 1,
      tension: 350,
      friction: 65,
      damping: 0.9,
      velocity: 0.02,
      clamp: true,
    },
    ref: moveRef,
  });
  const textRef = useRef();
  const aTextStyle = useSpring({
    opacity: isActive ? '1' : '0',
    transform: isActive ? 'scale(1)' : 'scale(0.65)',
    config: { mass: 1, tension: 300, friction: 50 },
    ref: textRef,
  });
  const imgRef = useRef();
  const aImgStyle = useSpring({
    opacity: isActive ? '1' : '0.3',
    config: { mass: 1, tension: 110, friction: 60 },
    ref: imgRef,
  });
  useChain(
    isActive ? [moveRef, imgRef, textRef] : [textRef, imgRef, moveRef],
    isActive ? [0, 0.15, 0.2] : [0, 0.05, 0.1]
  );

  const handleClick = () => {
    setActive(index);
  };

  return (
    <>
      <Animated
        ref={moveRef}
        css={{
          flexShrink: '0',
          pr: index === 0 || index === 1 ? 'calc(var(--gutter-width) / 2)' : '',
          width: ['full', undefined, 'calc(25% - var(--gutter-width) / 2)'],
          willChange: 'flex-grow',
          pos: 'relative',
        }}
        style={{ ...aMoveStyle, ...animationStyle }}
      >
        <LinkFrame
          css={{
            cursor: !isActive ? 'pointer' : '',
            pos: 'relative',
          }}
          icon="hover"
          onMouseEnter={handleClick}
        >
          <AnimatedAspectRatioBox
            ref={imgRef}
            className="aspect"
            css={{
              '&:before': {
                height: '0',
                content: "''",
                display: 'block',
                paddingBottom:
                  index === 0 || index === 1
                    ? `${(1 / (3 / 2)) * 100}%`
                    : `calc((100% - (var(--gutter-width) / 2)) * ${
                        1 / (3 / 2)
                      })`,
              },
            }}
            ratio={3 / 2}
            style={aImgStyle}
          >
            <Image
              absolute
              alt={image?.alternativeText}
              css={{ w: 'full', rounded: 'sm' }}
              image={image?.file}
              srcsetWidthDesktop="35vw"
            />
          </AnimatedAspectRatioBox>
          <Flex
            css={{
              align: 'flex-end',
              bottom: ['4', undefined, '4'],
              justify: 'space-between',
              pos: 'absolute',
              px: ['4', undefined, '4'],
              w: 'full',
            }}
          >
            <HeadingMd
              as="h4"
              classNames={`m-0 lg:-mr-16 overflow-hidden p-0 pr-2 ${
                isActive ? 'scale-100' : 'scale-75'
              } origin-bottom-left transition-transform duration-1000 will-change-transform w-auto lg:w-56`}
              // css={{
              //   m: '0',
              //   mr: [undefined, undefined, '-4rem'],
              //   overflow: 'hidden',
              //   p: '0',
              //   pr: '2',
              //   transform: isActive ? 'scale(1)' : 'scale(0.75)',
              //   transformOrigin: 'left bottom',
              //   transition: 'transform 1s ease',
              //   willChange: 'transform',
              //   w: ['auto', undefined, '14rem'],
              // }}
            >
              {title}
            </HeadingMd>
            <Icon
              className="link-icon"
              css={{
                flexShrink: '0',
                opacity: isActive ? '0' : '1',
                '& g': { stroke: 'tom.text' },
                size: ['8'],
                transition: 'opacity 0.5s ease',
                willChange: 'opacity',
              }}
              name="open"
            />
          </Flex>
        </LinkFrame>
        {isActive && (
          <TextMd
            ref={textRef}
            as={a.div}
            css={{
              pr: [4, undefined, '0px'],
              pt: [4, undefined, 3],
              w: [undefined, undefined, 'calc(25vw + 12rem)'],
              transformOrigin: [undefined, undefined, 'top left'],
            }}
            style={aTextStyle}
          >
            <Markdown>{description}</Markdown>
          </TextMd>
        )}
      </Animated>
    </>
  );
};

const CarouselItemAccordion = ({ index, active, setActive, item }) => {
  const isActive = index === active;
  // useEffect(() => {
  //   setBoxWidth(boxRef.current?.clientWidth);
  // }, [windowWidth, isActive]);
  const { description, title, image } = item;

  return (
    <>
      <Accordion
        key={index}
        onlySingleActive
        css={{
          pl: '0',
          pr: '0',
        }}
        header={(open) => (
          <LinkFrame
            css={{
              cursor: !isActive ? 'pointer' : '',
              mt: [index > 0 && 6, undefined, undefined],
              pos: 'relative',
            }}
            icon="hover"
            // onMouseEnter={handleClick}
          >
            <AnimatedAspectRatioBox
              // ref={imgRef}
              className="aspect"
              // style={aImgStyle}
            >
              <Image
                absolute
                alt={image?.alternativeText}
                css={{ w: 'full', rounded: 'sm' }}
                image={image?.file}
              />
            </AnimatedAspectRatioBox>
            <Flex
              css={{
                align: 'flex-end',
                bottom: ['4', undefined, '4'],
                justify: 'space-between',
                pos: 'absolute',
                px: ['4', undefined, '4'],
                w: 'full',
              }}
            >
              <HeadingMd
                as="h4"
                classNames="m-0 overflow-hidden pr-4"
                // css={{
                //   m: '0',
                //   overflow: 'hidden',
                //   pr: '4',
                // }}
              >
                {title}
              </HeadingMd>
              <Icon
                className="link-icon"
                css={{
                  flexShrink: '0',
                  opacity: open ? '0' : '1',
                  '& g': { stroke: 'tom.text' },
                  size: ['8'],
                  transition: 'opacity 0.25s ease',
                }}
                name="open"
              />
            </Flex>
          </LinkFrame>
        )}
        index={index}
        isActive={isActive}
        openDefault={index === 0}
        setActive={setActive}
        // pt={['2']}
      >
        <TextMd
          as="div"
          css={{
            pr: [4, undefined, '0px'],
            pt: [2, undefined, 3],
          }}
        >
          <Markdown>{description}</Markdown>
        </TextMd>
      </Accordion>
    </>
  );
};

export const Carousel = ({ items: inputItems = [], description, css }) => {
  const [active, setActive] = useState(0);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  // const doubleItems = [...inputItems, ...secondList];
  const [items, setItems] = useState(inputItems.slice(0, 3));
  const [slicer, setSlicer] = useState(3);

  const transition = useTransition(items, {
    from: { width: mounted ? '0%' : '25%', opacity: mounted ? 1 : 0 },
    enter: { opacity: 1, width: '25%' },
    leave: { opacity: 0, width: '0%' },
    keys: (item) => item.id,
  });

  const fragment = transition((style, item) => {
    const index = items.indexOf(item);

    return (
      <CarouselItem
        active={active}
        animationStyle={style}
        index={index}
        item={item}
        length={3}
        setActive={setActive}
      />
    );
  });

  const changeSlicer = (value) => {
    const curSlice = slicer + value;
    if (curSlice === inputItems.length + 1 && active !== inputItems.length)
      setActive(active + 1);
    if (curSlice === 2 && active !== 0) setActive(active - 1);
    if (curSlice === 2 || curSlice === inputItems.length + 1) return;

    setSlicer(curSlice);
    setItems(inputItems.slice(curSlice - 3, curSlice));
    // setActive(firstActive);
  };

  return (
    <Box
      css={{
        column: ['1/span 12', null, '1/span 12'],
        minH: [null, null, 'calc(26vw + 26rem)'],
        mt: [12, undefined, 32],
        pos: 'relative',
        ...css,
      }}
    >
      {inputItems.length > 3 && (
        <Box
          css={{
            pos: 'absolute',
            right: 0,
            top: '19em',
            d: ['none', undefined, 'block'],
            zIndex: 2,
          }}
        >
          <Icon
            css={{
              transform: 'rotate(180deg)',
              cursor: 'pointer',
              mr: [2],
              '&:hover g': {
                stroke: 'tom.textHighlight',
                color: 'tom.textHighlight',
              },
              '&:hover path': { stroke: 'tom.textHighlight' },
              visibility: slicer === 3 && active === 0 ? 'hidden' : 'visible',
            }}
            name="more"
            onClick={() => changeSlicer(-1)}
          />
          <Icon
            css={{
              cursor: 'pointer',
              '&:hover g': {
                stroke: 'tom.textHighlight',
                color: 'tom.textHighlight',
              },
              '&:hover path': { stroke: 'tom.textHighlight' },
              visibility:
                slicer === doubleItems.length && active === 2
                  ? 'hidden'
                  : 'visible',
            }}
            name="more"
            onClick={() => changeSlicer(1)}
          />
        </Box>
      )}
      {description && (
        <HeadingSm
          as="h3"
          classNames="pb-3 lg:pb-4 pr-[33%]"
          // css={{
          //   pb: [3, undefined, 4],
          //   pr: '33%',
          // }}
        >
          {description}
        </HeadingSm>
      )}
      <Flexy
        css={{
          direction: ['column', undefined, 'row'],
          overflowY: 'hidden',
          pos: 'relative',
          d: ['none', undefined, 'flex'],
        }}
      >
        {fragment}
      </Flexy>
      <Flex
        css={{
          direction: ['column', undefined, 'row'],
          overflowY: 'hidden',
          pos: 'relative',
          d: ['flex', undefined, 'none'],
        }}
      >
        {inputItems.map((item, index, { length }) => (
          <CarouselItemAccordion
            key={index}
            active={active}
            index={index}
            item={item}
            length={length}
            setActive={setActive}
          />
        ))}
      </Flex>
    </Box>
  );
};
