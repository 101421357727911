import { Link as GLink } from 'gatsby-plugin-react-i18next';
import { Box, Flex, Icon, Link } from 'juhuui';
import React from 'react';
import shallow from 'zustand/shallow';

import { LinkFrame, TextMd } from '../styles';
import { useStore } from '../zustand';

export interface Item {
  id: number;
  title: string;
  link?: string;
  [key: string]: any;
}

interface ListBoxBorderProps {
  items: Item[];
  props?: any;
}

function ListBoxBorder({ items, css, ...props }: ListBoxBorderProps) {
  const [setModalLevel1Visible, setModalLevel1Data] = useStore(
    (state) => [state.setModalLevel1Visible, state.setModalLevel1Data],
    shallow
  );
  const changeOverlay = ({ id, ...rest }, type: string) => {
    setModalLevel1Data({ name: type, id, ...rest });
    setModalLevel1Visible(true);
  };

  return (
    items &&
    items.map((item: Item, i: number, { length }) => (
      <LinkFrame
        // as={GLink}
        key={item.id}
        animate="true"
        className="list-box-border-item"
        css={{
          border: '1px solid tom.line',
          column: i % 2 === 0 ? '1/span 3' : '4/span 3',
          cursor: 'pointer',
          h: ['24', '32'],
          p: ['3', '3'],
          mr: '-1px',
          mb: '-1px',
          ...css,
        }}
        icon="hover"
        state={{ area: 'method', id: item.id }}
        text="hover"
        onClick={() => changeOverlay(item, 'method')}
        {...props}
      >
        <Flex
          css={{
            align: 'center',
            justify: 'space-between',
            overflow: 'hidden',
          }}
        >
          <TextMd
            className="link-text"
            css={{
              flexShrink: '1',
              overflow: 'hidden',
            }}
          >
            {item.title}
          </TextMd>
          <Icon
            className="link-icon"
            css={{
              size: '8',
              flexShrink: '0',
              ml: '3',
            }}
            name="more"
          />
        </Flex>
      </LinkFrame>
    ))
  );
}

export default ListBoxBorder;
